import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import ProductList from '../components/Product/ProductList';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import SeriesList from '../components/Series/SeriesList';
import PageLead from '../components/PageLead/PageLead';
import FeaturedCardsComponent from '../components/Cards/FeaturedCardsComponent';

const CollectionPages = ({
  data: {
    datoCmsCollection: { title, id, metaTags, pathways, seriesList, lead },
    allDatoCmsProduct,
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  const hasSeriesList = seriesList?.length > 0;

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      <PageLead leadText={lead} />
      {hasSeriesList ? (
        <SeriesList items={seriesList} />
      ) : (
        <ProductList products={allDatoCmsProduct.nodes} />
      )}
      <FeaturedCardsComponent pathways={pathways}></FeaturedCardsComponent>
    </PageWrapper>
  );
};

export default CollectionPages;

export const query = graphql`
  query CollectionPages($locale: String!, $id: String!) {
    datoCmsCollection(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      title
      lead
      bannerImage {
        alt
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "600", fit: "crop", w: "1440", q: 60 }
        )
      }
      seriesList {
        title
        id: originalId
        cardIntro
        cardImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "472"
              fit: "fill"
              fill: "solid"
              w: "700"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
        cardFeatures {
          text
        }
      }
      bannerText
      metaTags {
        title
        description
        image {
          url
        }
      }
      pathways {
        heading
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fm: "webp", h: "270", fit: "crop", w: "440", q: 60 }
          )
        }
        link {
          ...Link
        }
        subHeading
        text
      }
      id: originalId
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    allDatoCmsProduct(
      filter: {
        locale: { eq: $locale }
        collection: { originalId: { eq: $id } }
      }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        locale
        id: originalId
        title
        description
        productFeatureList {
          specReference {
            title
            titleTranslation
          }
          value
          showOnProductCard
        }
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "450"
              fit: "fill"
              fill: "solid"
              w: "680"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
        hoverImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "450"
              fit: "fill"
              fill: "solid"
              w: "680"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
      }
    }
  }
`;
