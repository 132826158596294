import React from 'react';

const IconeCheckbox = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.656" height="19.852">
      <path
      fill='#425363'
        d="m4.945 9.2 1.428-1.32a27.475 27.475 0 0 1 4.611 2.764A39.951 39.951 0 0 1 21.191 1.9l.466 1.07c-3.578 3.122-6.2 6.6-9.972 13.367A71.6 71.6 0 0 0 4.945 9.2zm13 .5a8.116 8.116 0 1 1-8.019-6.9 8.064 8.064 0 0 1 4.759 1.555c.47-.4.949-.795 1.444-1.176a9.926 9.926 0 1 0 3.241 4.699A37.81 37.81 0 0 0 17.945 9.7z"
        transform="translate(0 -1)"
      />
    </svg>
  );
};

export default IconeCheckbox;