import React from 'react';
import style from './series-card.mod.scss';
import Container from '../Layout/SharedStyles/Container';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../Button/Button';
import IconCheckbox from '../Layout/Icons/IconCheckbox';

const SeriesCard = ({ title, cardIntro, cardImage, cardFeatures, id }) => {
  return (
    <div className={style.seriescard}>
      <div className={style.seriescard__wrapper}>
        <div>
          <GatsbyImage image={cardImage.gatsbyImageData} />
          <p className={style.seriescard__title}>{title}</p>
          <p>{cardIntro}</p>
          <ul>
            {cardFeatures.map(({ text }, i) => (
              <li key={i}>
                <IconCheckbox />
                <span>{text}</span>
              </li>
            ))}
            <div className={style.seriescard__button}>
              <Button
                fullWidth={true}
                recordId={id}
                secondary
              >{`View all ${title} spas`}</Button>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SeriesCard;
