import React from 'react';
import style from './series-list.mod.scss';
import Container from '../Layout/SharedStyles/Container';
import SeriesCard from './SeriesCard';

const SeriesList = ({ items }) => {
  return (
    <Container width="medium" pt={2} pb={2}>
      <div className={style.list}>
        <div className={style.list__grid}>
          {items.map((item, i) => (
            <SeriesCard key={i} {...item} />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default SeriesList;
